$color-black: #030D19;
$color-light: #ededed;
$color-white: #fff;
$color-green: #51E314;
$color-light-small: #D5DDF0;
$color-off-white: #7C89B4;
$color-light-blue: #2C3441;
$color-dark: #2C2C2C;
$color-small-white: #E7E7E7;
$color-purple: #6F7EFD;
$color-blue: #5B639C;
$error: red;
$radius5: 5px;
$radius6: 6px;
$radius10: 10px;
$radius30: 30px;
$small: 300px;
$medium: 900px;
$font-weight5: 500;
$font-weight6: 600;
$font-weight7: 700;
$font-family: 'Montserrat', sans-serif;
$font-size14: 14px;
$font-size24: 24px !important;
$font-size12: 12px;
$font-size10: 10px;
$text-underline: underline;
$font-size18: 18px;
$box-shadow4: 0px 4px 4px rgba(0, 0, 0, 0.25);

body {
  background-color: $color-black;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0 0 10px;
}

.error {
  color: $error;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container {
  padding: 150px 10px 50px;
  font-family: $font-family;
  font-size: $font-size14;

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .white {
    color: $color-white;
  }

  .border-dots {
    padding-bottom: 30px;
    border-bottom: 1px dashed $color-blue;
  }

  .wrapper {

    .h3 {
      font-family: $font-family;
      font-weight: $font-weight6;
      font-size: $font-size18;
      color: $color-white;
    }


    .pt25 {
      padding-top: 25px;
    }

    .pt10 {
      padding-top: 10px;
    }

    .fontweight5 {
      font-weight: $font-weight5;
    }

    .fontweight6 {
      font-weight: $font-weight6;
    }

    .button-purple {
      height: 42px;
      background: linear-gradient(0deg, rgba(220, 220, 220, 0.2), rgba(220, 220, 220, 0.2)), #6F7EFD;
      border-radius: $radius30;
      font-family: $font-family;
      font-weight: $font-weight6;
      font-size: $font-size12;
      color: $color-white;
      padding: 10px 30px;
      border: none;
    }

    .button-green {
      height: 42px;
      background: linear-gradient(0deg, rgba(220, 220, 220, 0.2), rgba(220, 220, 220, 0.2)), #51E314;
      border-radius: $radius30;
      font-family: $font-family;
      font-weight: $font-weight6;
      font-size: $font-size12;
      color: $color-white;
      padding: 10px 30px;
      border: none;
    }

    .marginTop40 {
      margin-top: 40px;
    }

    .underlineText {
      color: $color-white;
      font-size: $font-size12;
      text-decoration: $text-underline;
      font-weight: $font-weight5;
    }

    .relative {
      position: relative;
    }

    .ruppees {
      position: absolute;
      left: 0;
      top: 25px;
      font-size: 24px;
      padding: 0 20px;
      border-right: 1px solid #E3E4EA;
    }


    .underlineTextDark {
      color: $color-light-blue;
      font-size: $font-size12;
      text-decoration: $text-underline;
      font-weight: $font-weight5;
    }

    .noUnderlineText {
      color: $color-white;
      font-size: $font-size12;
      font-weight: $font-weight5;
    }

    .light_bg_card {
      background: $color-light-small;
      border: 1px solid $color-off-white;
      box-sizing: border-box;
      box-shadow: $box-shadow4;
      border-radius: $radius6;
      padding: 30px 20px 20px;
      margin-top: 5px;

      &.cardPadding {
        padding: 20px 0 10px;
      }

      &.cardPadding20 {
        padding: 20px;
      }

      .utilities-box {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;

        div {
          padding: 10px;
          margin: 5px;
          border-radius: $radius6;
          background-color: $color-white;
          text-align: center;

          &:active {
            background-color: $color-purple
          }

          img {
            max-width: 20px;
            margin: auto;
          }

          p {
            margin: 0;
            text-align: center;
            font-weight: $font-weight6;
            font-size: $font-size12;
          }
        }
      }

      &.purple_card {
        padding-top: 20px;
        background: $color-purple;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
      }

      .row-structure {
        display: flex;
        width: 100%;

        div {
          width: 100%;
        }

        div:last-child {
          text-align: right;
        }
      }

      .tabular-form {
        display: block;
        width: 100%;
        padding-top: 10px;
        font-size: 10px;

        .row-structured {
          display: flex;
          width: 100%;

          &.head {
            color: $color-blue;
            font-weight: $font-weight7;
          }

          &:nth-child(odd) {
            background-color: $color-white
          }

          div {
            width: 100%;
            padding: 10px 0 10px 20px;
          }
        }

        &.alternate {
          .row-structured {
            &:nth-child(odd) {
              background-color: transparent;
            }
          }
        }
      }

      .label {
        color: $color-light-blue;
        display: block;
        padding-bottom: 10px;
        font-weight: $font-weight5;
      }
.newLight{
  margin: 0 10px 10px 20px;
}
      .company-select {
        width: 100%;
        height: 46px;
        background: $color-light;
        border: 1px solid $color-white;
        box-shadow: $box-shadow4;
        border-radius: $radius5;
        padding: 10px 20px;
        font-family: $font-family;
        font-weight: $font-weight5;
        font-size: $font-size14;
        margin-bottom: 30px;

        option {
          background-color: $color-white;

          &::selection {
            background-color: $color-small-white;
          }

          &:nth-child(even) {
            background-color: $color-small-white;
          }
        }
      }

      .input-normal {
        width: 100%;
        height: 46px;
        background: $color-light;
        border: 1px solid $color-white;
        box-shadow: $box-shadow4;
        border-radius: $radius5;
        padding: 10px 20px;
        font-family: $font-family;
        font-weight: $font-weight5;
        font-size: $font-size14;
        margin-bottom: 10px;
        margin-top: 10px;

        &:focus,
        &:hover {
          background: $color-white;
          outline: none;
        }

        &.error {
          background: $color-white;
          border: 2px solid $error;
        }

        &:disabled {
          background: $color-white;
          border-color: $color-white;
          cursor: not-allowed;
        }
      }

      .button-normal {
        height: 42px;
        background: linear-gradient(0deg, rgba(220, 220, 220, 0.2), rgba(220, 220, 220, 0.2)), #000000;
        border-radius: $radius30;
        font-family: $font-family;
        font-weight: $font-weight6;
        font-size: $font-size14;
        color: $color-white;
        padding: 10px 30px;
        border: none;
        margin-top: 20px;

        &.mt5 {
          margin-top: 5px;
        }
      }

    }

    .fontsize24 {
      font-size: $font-size24;
    }

    .fontsize10 {
      font-size: $font-size10;
    }

  }
}
